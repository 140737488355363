import type {
  CreateAbility,
  ExtractSubjectType,
  MongoAbility,
  MongoQuery,
  SubjectRawRule,
} from '@casl/ability'
import { AbilityBuilder, createMongoAbility } from '@casl/ability'
import type { AuthUser } from '@authentication'
import { Roles } from '~/constants/Roles'
import type { Product } from '~/composables/useAPI/types'

export type Actions =
  | 'create'
  | 'read'
  | 'update'
  | 'delete'
  | 'manage'
  | 'product'

export type Subjects =
  | 'Organization'
  | 'Companies'
  | 'Company'
  | 'Users'
  | 'User'
  | 'InvitedUsers'
  | 'Leases'
  | 'MyProfile'
  | Product

export type AppAbility = MongoAbility<[Actions, Subjects]>

const createAppAbility = createMongoAbility as CreateAbility<AppAbility>

export const defineAbilityFor = (user?: AuthUser) => {
  const { build } = new AbilityBuilder(createAppAbility)
  return build()
}

export const updateAbilityFor = (
  user?: AuthUser,
): SubjectRawRule<Actions, ExtractSubjectType<Subjects>, MongoQuery>[] => {
  //

  const { can, rules } = new AbilityBuilder(createAppAbility)

  // Organization admin
  user?.permissions?.forEach((permission) => {
    const { roleId } = permission

    switch (roleId) {
      case Roles.OrganizationAdmin.id: // organizationAdmin
        can(
          ['read', 'create', 'update', 'delete', 'manage'],
          [
            'Organization',
            'Companies',
            'Company',
            'Users',
            'User',
            'InvitedUsers',
            'Leases',
            'MyProfile',
          ],
        )
        break
      case Roles.CompanyAdmin.id: // companyAdmin
        can(['update', 'manage'], ['Leases', 'Company'])
        can('read', [
          'Organization',
          'Companies',
          'Company',
          'Users',
          'MyProfile',
        ])
        break
      case Roles.LeaseContributor.id: // leaseContributor
        can('read', [
          'Organization',
          'Companies',
          'Users',
          'MyProfile',
          'Company',
          'Leases',
        ])
        can(['update', 'manage'], ['Leases'])
        break
    }
  })

  user?.products.forEach((product) => {
    can('product', product)
  })

  return rules
}
