export const useEnv = () => {
  const { ENV } = useRuntimeConfig().public
  const env = computed(() => ENV)
  const isLocal = computed(() => ENV === 'local')
  const isStaging = computed(() => ENV === 'staging')
  const isProduction = computed(() => ENV === 'production')
  const isDev = computed(() => isLocal.value || isStaging.value)

  return { env, isLocal, isStaging, isProduction, isDev }
}
